import React from "react"
import { DeductivePageLink } from "./link"

export interface CTAProps {
  title: string
  internalLink?: any
  externalLink?: string
}

export const PrimaryCTA = (data: CTAProps) => {
  const { title, internalLink, externalLink } = data
  return (
    <DeductivePageLink
      externalLink={externalLink}
      internalLink={internalLink}
      className="bg-violet text-white px-6 py-2 inline-block lg:text-lg hover:bg-violet-darker transition duration-150"
    >
      {title}
    </DeductivePageLink>
  )
}

export const SecondaryCTA = (data: CTAProps) => {
  const { title, internalLink, externalLink } = data
  return (
    <DeductivePageLink
      externalLink={externalLink}
      internalLink={internalLink}
      className="text-sm uppercase hover:text-green-dark border-b border-green inline-block transition duration-200 hover:border-transparent tracking-wider"
    >
      {title}
    </DeductivePageLink>
  )
}
