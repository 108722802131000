import React from "react"
import cx from "classnames"
import { SecondaryCTA, CTAProps } from "../cta"
import { Image } from "../image"
import lambImage from "../../images/lamb.svg"
import wheelImage from "../../images/wheel.svg"
import yarnImage from "../../images/yarn.svg"

export interface FiftyFiftyModuleProps {
  data: {
    sections: Array<{
      headline: string
      body: string
      cta: CTAProps
      image: any
      illustration: any
      slug: {
        current: string
      }
    }>
  }
}

export const FiftyFiftyModule = ({ data }: FiftyFiftyModuleProps) => {
  const { sections } = data
  console.log(data)

  return (
    <div className="max-w-6xl mx-auto w-full bg-white md:my-20">
      {sections.map(
        ({ headline, body, cta, image, illustration, slug }, index) => {
          const illustrationImage = {
            yarn: yarnImage,
            lamb: lambImage,
            wheel: wheelImage,
            default: yarnImage,
          }[illustration]
          return (
            <div
              key={index}
              className={`flex flex-col ${
                index === 0 || index % 2 === 0
                  ? "md:flex-row"
                  : "md:flex-row-reverse"
              }`}
              id={slug.current}
            >
              <Image
                imageId={image.asset.id}
                className="md:flex-1"
                aspectRatio={1}
              />
              <div className="md:flex-1 flex flex-col relative">
                <div className="px-4 flex-1 flex justify-center flex-col md:mx-6 max-w-sm mx-auto">
                  <div className="pt-6 pb-16">
                    <h2 className="mb-2 lg:mb-4 font-sans font-bold md:text-4xl">
                      {headline}
                    </h2>
                    <p className="mb-6">{body}</p>
                    <SecondaryCTA {...cta} />
                  </div>
                </div>
                <img
                  src={illustrationImage}
                  className="hidden md:block absolute bottom-0 right-0 w-32 h-32 lg:w-40 lg:h-40 ml-auto m-6"
                />
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}
