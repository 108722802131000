import React from "react"

import { RenderModules } from "../utils/renderModules"
import { SEO } from "../components/seo"

export interface PageProps {
  pageContext: {
    main: {
      displayTitle?: boolean
      modules: []
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
  }
  path?: string
  preview?: boolean
}

const Page = ({ path, pageContext, preview = false }: PageProps) => {
  const {
    main: { modules, title, displayTitle, slug },
    meta,
  } = pageContext

  const url = slug.current === "home" ? "" : path
  return (
    <div className="mt1 x ac">
      {preview && <div className="bcblue ac cw x p1">This is a Preview</div>}
      <SEO metaInfo={meta} pagePath={url} />
      {displayTitle && <h1 className="text-center my-4">{title}</h1>}
      {RenderModules(modules)}
    </div>
  )
}

export default Page
