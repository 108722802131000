import React from "react"
import cx from "classnames"

import { Image } from "../image"

export interface ImageModuleProps {
  data: {
    image: any
    caption?: string
    layout?: "full" | "large" | "medium"
  }
}

export const ImageModule = ({ data }: ImageModuleProps) => {
  const { image, caption, layout } = data
  const layoutClass = {
    full: "w-full",
    large: "w-full max-w-6xl mx-auto",
    medium: "w-full max-w-4xl mx-auto",
    default: "",
  }[layout]
  return (
    <div className={`${layoutClass} mb-4`}>
      <Image imageId={image.asset.id} className={"w-full"} />
    </div>
  )
}
