import React from "react"

import { StandardText, StandardTextProps } from "./global/standardText"
import {
  FiftyFiftyModule,
  FiftyFiftyModuleProps,
} from "./global/fiftyFiftyModule"
import { HeroModule, HeroModuleProps } from "./global/heroModule"
import { ImageModule, ImageModuleProps } from "./global/imageModule"

export const Modules = ({
  reactModule,
  type,
}: {
  type: string
  reactModule: any
}) => {
  switch (type) {
    case "standardText":
      return <StandardText data={reactModule as StandardTextProps["data"]} />
    case "fiftyFiftyModule":
      return (
        <FiftyFiftyModule data={reactModule as FiftyFiftyModuleProps["data"]} />
      )
    case "heroModule":
      return <HeroModule data={reactModule as HeroModuleProps["data"]} />
    case "imageModule":
      return <ImageModule data={reactModule as ImageModuleProps["data"]} />
    default:
      return <span>{type}</span>
  }
}
