import React from "react"

import { BackgroundImage } from "../image"
import { PrimaryCTA, CTAProps } from "../cta"

export interface HeroModuleProps {
  data: {
    image: any
    headline: string
    cta: CTAProps
  }
}

export const HeroModule = ({ data }: HeroModuleProps) => {
  const { image, headline, cta } = data
  return (
    <div className="">
      <BackgroundImage
        imageId={image.asset.id}
        className={"w-full"}
        hotspot={image.hotspot}
        bgColor={image.asset.metadata.palette.muted.background}
      >
        <div className="md:max-w-6xl mx-auto h-80vh flex items-center px-6">
          <div className="max-w-xl">
            <h1 className="text-white leading-snug mb-6 text-4xl md:text-5xl lg:text-6xl">
              {headline}
            </h1>
            <PrimaryCTA {...cta} />
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}
